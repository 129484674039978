import { updateProgress } from "./data.service";
import config from "../config/config";

const TOKEN = window.localStorage.getItem('Authorization');
// export const API_URL = config.API_URL
const API_URL = "http://localhost:8000/api/servisor/v1"
// const API_URL = "https://4744-102-132-140-69.ngrok-free.app/api/servisor/v1"
console.log(API_URL)


export const LIST = async (url: string) => {
    try {
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const POST = async (url: string, params: any) => {
    try {
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(params)
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const GET = async (url: string, params: any) => {
    try {
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const PUT = async (url: string, params: any) => {
    try {
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(params)
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const CUSTOMPUT = async (url: string, params: any) => {
    try {
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(params)
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const DELETE = async (url: string, params: any) => {
    try {
        const response = await fetch(`${API_URL}/${url}/${params._id.$oid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${TOKEN}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}

export const POSTFILE = async (url: string, file: any, target: string) => {
    try {
        const formData = new FormData();
        formData.append("file", file.file, file.name);
        
        const response = await fetch(`${API_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${TOKEN}`
            },
            body: formData
        });

        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw data;
        }
    } catch (error) {
        throw error;
    }
}
 