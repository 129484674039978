import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import "./Profile.scss";
import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import {  updateUser } from "../../../services/user.service";
import { getUser } from "../../../services/user.service";

const Profile: React.FC = () => {
  const [user, setUser]: any = useState();
  const [clicked, setClicked]: any = useState(false);
  const [success, setSuccess]: any = useState(false);

  // useEffect(() => {
  //   try {
  //   //   getUser().then((userData: any) => {
  //   //     console.log(userData)
  //   //     setUser(userData.user_data);
  //   //     console.log(user)
  //   //   });
  //   // } catch (error) {
  //   //   console.log(error);
  //   //   }
 
    
  // }, []);

  // const saveProfile = () => {
  //   setClicked(true);
    
  //   updateUser(user)
  //     .then((userData: any) => {
  //       setUser(userData);
  //       setClicked(false);
  //       setSuccess(true);
  //     })
  //     .catch((error: any) => {
  //       setClicked(false);
  //     });
  // };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    ></path>
                  </svg>
                  <span>Profile</span>
                </h6>
              </div>
              {/* {user ? ( */}
                <div className="content-container grey-bg">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Below you can edit your profile information.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                // defaultValue={user.name}
                                // onChange={(ev) => {
                                //   console.log(ev.target.value);
                                //   user.name = ev.target.value;
                                //   setUser(user);
                                // }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Surname
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                // defaultValue={user.surname}
                                // onChange={(ev) => {
                                //   user.surname = ev.target.value;
                                //   setUser(user);
                                // }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Email
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                // defaultValue={user.email}
                                // onChange={(ev) => {
                                //   user.email = ev.target.value;
                                //   setUser(user);
                                // }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Mobile Number
                              </label>
                              <input
                                className="form-control"
                                type="tel"
                                // defaultValue={user.phone_number}
                                // onChange={(ev) => {
                                //   user.phonenumber = ev.target.value;
                                //   setUser(user);
                                // }}
                              />
                            </div>
                            <button
                              disabled={clicked}
                              className="btn btn-warning btn-lg w-100"
                              type="button"
                              // onClick={() => {
                              //   saveProfile();
                              // }}
                            >
                              Save
                              {clicked ? (
                                <IonSpinner name="crescent"></IonSpinner>
                              ) : null}

                            </button>
                          </form>
                          {success && (
                            <div className="alert alert-success mt-3">
                              Profile updated successfully!
                            </div>
                          )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/* ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="k-loading-image" />
                </div>
              )} */}
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Profile;
