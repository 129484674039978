import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core SCSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic SCSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional SCSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./theme/bootstrap.scss";
import "./theme/variables.scss";
import "./theme/main.scss";

import Signin from "./pages/Auth/Signin/Signin";
import Forgot from "./pages/Auth/Forgot/Forgot";
import Reset from "./pages/Auth/Reset/Reset";
import Diary from "./pages/Dashboard/Diary/Diary";
import Bookings from "./pages/Dashboard/Bookings/Bookings";
import Invoices from "./pages/Dashboard/Invoices/Invoices";
import Workshop from "./pages/Dashboard/Workshop/Workshop";
import Business from "./pages/Dashboard/Business/Business";
import Profile from "./pages/Dashboard/Profile/Profile";
import ManageUsers from "./pages/Admin/ManageUsers/ManageUsers";
import ManageWorkshops from "./pages/Admin/ManageWorkshops/ManageWorkshops";
import ManageInvoices from "./pages/Admin/ManageInvoices/ManageInvoices";
import NotFound from "./pages/NotFound/NotFound";
import Settings from "./pages/Dashboard/Settings/Settings";
import AdminSignIn from "./pages/Auth/Signin/AdminSignIn";
import ManagePayments from "./pages/Admin/ManagePayments/ManagePayements";
import ManageBookings from "./pages/Admin/ManageBookings/ManageBookings";

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/signin" exact={true} component={Signin}></Route>
        <Route path="/admin-signin" exact={true} component={AdminSignIn}></Route>
        <Route path="/forgot" exact={true} component={Forgot}></Route>
        <Route path="/reset" exact={true} component={Reset}></Route>
        <Route path="/diary" exact={true} component={Diary}></Route>
        <Route path="/bookings" exact={true} component={Bookings}></Route>
        <Route path="/invoices" exact={true} component={Invoices}></Route>
        <Route path="/workshop" exact={true} component={Workshop}></Route>
        <Route path="/business" exact={true} component={Business}></Route>
        <Route path="/profile" exact={true} component={Profile}></Route>
        <Route path="/settings" exact={true} component={Settings}></Route>
        <Route
          path="/manage-users"
          exact={true}
          component={ManageUsers}
        ></Route>
        <Route
          path="/manage-workshops"
          exact={true}
          component={ManageWorkshops}
        ></Route>
           <Route
          path="/manage-bookings"
          exact={true}
          component={ManageBookings}
        ></Route>
           <Route
          path="/manage-Payments"
          exact={true}
          component={ManagePayments}
        ></Route>
        <Route
          path="/manage-invoices"
          exact={true}
          component={ManageInvoices}
        ></Route>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <Route path="/not-found" exact={false} component={NotFound}></Route>
        <Redirect to="/not-found" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
