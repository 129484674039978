import { POST, PUT, LIST, GET } from "./api.service" 

export const saveService = (data: any) => {
    return POST('services', data);
}
export const getServices = (id: any) => {
    return LIST(`workshopservices/${id}`);
}
export const updateService = (data: any) => {
    return PUT(`service`, data);
}
export const getServiceById = (data: any) => {
    return GET(`service`, data);
}
export const getServiceinvoices = (id: string) => {
    return LIST(`serviceinvoices/${id}`);
}
export const getAdmininvoices = () => {
    return LIST(`workshopadminservices`);
}