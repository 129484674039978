import { IonButton, IonContent, IonHeader, IonPage, IonProgressBar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../../components/header/Header';
import StartModal from '../../../components/modals/StartModal';
import './Business.scss';

import UploadIcon from '../../../assets/img/upload-icon.svg';
import UploadedIcon from '../../../assets/img/uploaded-icon.svg';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import DashHeader from '../../../components/dash-header/DashHeader';
import LeftMenu from '../../../components/left-menu/LeftMenu';
import { business, uploadProgress, workshop } from '../../../services/data.service';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Geocode, initService } from '../../../services/location.service';
import { updatebusiness } from '../../../services/business.service';
import { media, upload } from '../../../services/upload.service';
import { getUser, getBankingInfo } from '../../../services/user.service';

const Business: React.FC = () => {

  const fileInput = useRef(null);

  const history = useHistory();
  const [rmiNumber, setRmiNumber] = useState('');
  const [workshopInfo, setWorkshopInfo]: any = useState(null);
  const [activeType, setActiveType]: any = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading]: any = useState({
    company_reg: false,
    bank_letter: false,
    proof_of_address: false,
    identification_document: false
  });
  const [loadingcompany_reg, setLoadingcompany_reg]: any = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaUploads, setMediaUploads]: any = useState({});
  const [company_reg, setcompany_reg] = useState({progress: null, error: null})
  const [bank_letter, setbank_letter] = useState({progress: null, error: null})
  const [proof_of_address, setproof_of_address] = useState({progress: null, error: null})
  const [identification_document, setidentification_document] = useState({progress: null, error: null})

  const [banks, setBanks]: any = useState([
    { 'name': 'Absa', id: 1 },
    { 'name': 'FNB', id: 2 },
    { 'name': 'Capitec', id: 3 },
    { 'name': 'Nedbank', id: 4 }
  ]);

  const [accounts, setAccounts]: any = useState([
    { 'name': 'Cheques', id: 1 },
    { 'name': 'Savings', id: 2 },
    { 'name': 'Transaction', id: 3 }
  ]);

  const [bankingInfo, setBankingInfo]: any = useState(null);

  const saveBusiness = () => {
    setWorkshopInfo(workshopInfo)
    updatebusiness(workshopInfo).then( (d: any) => {
      setWorkshopInfo(d.user_data);
    })
  }

  const businessDetails = {
    "user_data": {
        "address": "Eastgate Shopping Centre, Bradford Road, Bedfordview, Germiston, South Africa",
        "email": "luke.templeman@injozi.biz",
        "latlng": [
            -26.180388,
            28.1172088
        ],
        "name": "Luke",
        "new_cars": "true",
        "old_cars": "true",
        "parts_discount": "6",
        "phone_number": "0474747",
        "province": {
            "id": 3,
            "name": "Gauteng"
        },
        "rate": "2",
        "rate_discount": "6",
        "reg_no": "erg",
        "surname": "sTee",
        "vat_no": "rew",
        "vehicles": [
            {
                "logo": "https://www.car-logos.org/wp-content/uploads/2011/09/aixam.png",
                "name": "Aixam"
            },
            {
                "logo": "https://www.car-logos.org/wp-content/uploads/2011/09/acura.png",
                "name": "Acura"
            }
        ],
        "workshop_name": "Luke"
    }
}



  useEffect(() => {
    // getBankingInfo().then((d: any) => {
    //   setBankingInfo(g)
    // }).catch((d: any) => {
    //   console.log(d)
    // })

    // getUser().then((d: any) => {
    //   if (d) {
    //     console.log("got user", d)
    //     setWorkshopInfo(d.user_data);
    //   }
    // }).catch((d: any) => {
    //   console.log(d)
    // })
    setWorkshopInfo(businessDetails.user_data)


  }, [])

  // useEffect(() => {
  //   workshop.subscribe( (d: any) => {
  //     console.log(d)
  //     if (d) {
  //       const r = Object.assign({}, d);
  //       delete r.workshop;
  //       delete r.rmidetails;
  //       setWorkshopInfo(r);
  //       setTimeout(() => {
  //         // @ts-ignore
  //         document.getElementById('location-search').value = r.address;
  //         getUploads();
  //       }, 1000)
  //     }
  //   })
  //   uploadProgress.subscribe( (d: any) => {
  //     console.log('#######');
  //     console.log(d);
  //     console.log('#######');
  //     if (d) {
  //       switch(d._id) {
  //         case 'company_reg': {
  //           company_reg.progress = d.progress.loaded < d.progress.total ? d.progress : null;
  //           setcompany_reg(company_reg);
  //           break;
  //         }
  //         case 'bank_letter': {
  //           bank_letter.progress = d.progress.loaded < d.progress.total ? d.progress : null;
  //           setbank_letter(bank_letter);
  //           break;
  //         }
  //         case 'proof_of_address': {
  //           proof_of_address.progress = d.progress.loaded < d.progress.total ? d.progress : null;
  //           setproof_of_address(proof_of_address);
  //           break;
  //         }
  //         case 'identification_document': {
  //           identification_document.progress = d.progress.loaded < d.progress.total ? d.progress : null;
  //           setidentification_document(identification_document);
  //           break;
  //         }
  //         default:
  //           break;
  //       }
  //     }
  //   })
  // }, [])

  // const handleFileInput = (e: any) => {
  //   // handle validations
  //   const file = e.target.files[0];
  //   console.log('activeType')
  //   console.log(activeType)
  //   console.log('activeType')
  //   switch(activeType) {
  //     case 'company_reg': {
  //       setLoadingcompany_reg(true);
  //       break;
  //     }
  //     case 'bank_letter': {
  //       loading.bank_letter = true;
  //       setLoading(loading);
  //       break;
  //     }
  //     case 'proof_of_address': {
  //       loading.proof_of_address = true;
  //       setLoading(loading);
  //       break;
  //     }
  //     case 'identification_document': {
  //       loading.identification_document = true;
  //       setLoading(loading);
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  //   console.log(loading);
  //   upload(file, activeType, workshopInfo._id.$oid).then((d: any) => {
  //     getUploads();
  //     if (d) {
  //       switch(d.target) {
  //         case 'company_reg': {
  //           setLoadingcompany_reg(false);
  //           break;
  //         }
  //         case 'bank_letter': {
  //           loading.bank_letter = false;
  //           setLoading(loading);
  //           break;
  //         }
  //         case 'proof_of_address': {
  //           loading.proof_of_address = false;
  //           setLoading(loading);
  //           break;
  //         }
  //         case 'identification_document': {
  //           loading.identification_document = false;
  //           setLoading(loading);
  //           break;
  //         }
  //       }
  //     }
  //   }).catch((d: any) => {
  //     switch(d._id) {
  //       case 'company_reg': {
  //         company_reg.progress = null;
  //         company_reg.error = d.error;
  //         setcompany_reg(company_reg);
  //         break;
  //       }
  //       case 'bank_letter': {
  //         bank_letter.progress = null;
  //         bank_letter.error = d.error;
  //         setbank_letter(bank_letter);
  //         break;
  //       }
  //       case 'proof_of_address': {
  //         proof_of_address.progress = null;
  //         proof_of_address.error = d.error;
  //         setproof_of_address(proof_of_address);
  //         break;
  //       }
  //       case 'identification_document': {
  //         identification_document.progress = null;
  //         identification_document.error = d.error;
  //         setidentification_document(identification_document);
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   })
  // }

  // const getUploads = () => {
  //   media().then((d: any) => {
  //     if (d) {
  //       setMediaUploads(d);
  //     }
  //   }).catch((d: any) => {
  //   })
  // }

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
            <DashHeader />
            <div className="container-section">
              <LeftMenu />
              <div className="right-content-section">
                  <div className="header-container">
                      <h6><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" className="bi bi-briefcase-fill">
                                    <path fillRule="evenodd" d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"></path>
                                    <path fillRule="evenodd" d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v1.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"></path>
                                </svg><span>Business</span></h6>
                  </div>
             
                    {workshopInfo ? ( 
                        <div className="content-container grey-bg">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="card mb-3">
                                <div className="card-body p-0">
                                    <h6 className="card-title fw-bolder mb-3">Please fill in your business contact details.</h6>
                                    <div className="spacer"></div>
                                    <form>
                                        <div className="form-group">
                                          <label className="form-label fw-bolder">Workshop Telephone Number</label>
                                          <input className="form-control" type="tel" defaultValue={
                                            workshopInfo.phone_number
                                          } onChange={(ev) => {
                                            workshopInfo.phone_number = ev.target.value;
                                          }} />
                                        </div>
                                        <div className="form-group">
                                          <label className="form-label fw-bolder">Workshop email address</label>
                                          <input className="form-control" type="email"  defaultValue={
                                          workshopInfo.email
                                           
                                          } onChange={(ev) => {
                                            workshopInfo.email = ev.target.value;
                                            
                                          }} />
                                        </div>
                                        <div className="form-group">
                                          <label className="form-label fw-bolder">Search Address</label>
                                          <input className="form-control" defaultValue={workshopInfo.address} id="location-search" type="text" onInput={(ev) => {
                                            // @ts-ignore
                                            initService(ev.target.value).then( (d: any) => {
                                              console.log(d);
                                              setLocations(d);
                                            })
                                          
                                          }} />
                                        </div>
                                        {
                                          locations.length > 0 ? (
                                            locations.map((l: any) => {
                                              console.log(l)
                                              return (
                                                <>
                                                  <div className="result-list" onClick={() => {
                                                    workshopInfo.address = l.description;
                                                    workshopInfo.place_id = l.place_id;
                                                    Geocode(l.description).then( (d: any) => {
                                                      console.log(d.geometry.location.lat());
                                                      // @ts-ignore
                                                      document.getElementById('location-search').value = l.description;
                                                      workshopInfo.latlng = [d.geometry.location.lat(), d.geometry.location.lng()];
                                                      setLocations([])
                                                    })
                                                  }}>
                                                    <p className="m-0 p-2">{l.description}</p>
                                                  </div>
                                                </>
                                              )
                                            })
                                          ) : null
                                        }
                                    </form>
                                    <IonButton color="warning" size="small" onClick={() => {saveBusiness()}}>Save</IonButton>
                                </div>
                              </div>
                              <div className="card mb-3">
                                  <div className="card-body p-0">
                                      <h6 className="card-title fw-bolder mb-3">Provide your Company registration and VAT details.</h6>
                                      <div className="spacer"></div>
                                      <form>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Company Registration No</label>
                                            <input className="form-control" type="text" readOnly={true}  defaultValue={workshopInfo.reg_no} onChange={(ev) => {
                                              workshopInfo.reg_no = ev.target.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Company VAT No</label>
                                            <input className="form-control" type="text" readOnly={true}  defaultValue={workshopInfo.vat_no} onChange={(ev) => {
                                              workshopInfo.vat_no = ev.target.value;
                                            }} />
                                          </div>
                                      </form>
                                      <IonButton color="warning" size="small" onClick={() => {saveBusiness()}}>Save</IonButton>
                                  </div>
                              </div>
                              <div className="card mb-3">
                                  <div className="card-body p-0">
                                      <h6 className="card-title fw-bolder mb-3">Let’s get you paid!&nbsp;Fill in your company banking details.</h6>
                                      <div className="spacer"></div>
                                      <form>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Select Bank</label>
          
                                            <DropDownList data={banks} defaultValue={"workshopInfo.bank"} textField="name" dataItemKey="id" onChange={ (ev) => {
                                              workshopInfo.bank = ev.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Account Type</label>
                                            <DropDownList data={accounts} defaultValue={"workshopInfo.bank_account_type"} textField="name" dataItemKey="id" onChange={ (ev) => {
                                              workshopInfo.bank_account_type = ev.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Account No</label>
                                            <input className="form-control" type="text"  defaultValue={"workshopInfo.bank_account_no"} onChange={(ev) => {
                                              workshopInfo.bank_account_no = ev.target.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Branch Code</label>
                                            <input className="form-control w-50" type="text"  defaultValue={"workshopInfo.bank_branch_code"} onChange={(ev) => {
                                              workshopInfo.bank_branch_code = ev.target.value;
                                            }} />
                                          </div>
                                      </form>
                                      <IonButton color="warning" size="small" onClick={() => {saveBusiness()}}>Save</IonButton>
                                  </div>
                              </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="card mb-3">
                                  <div className="card-body p-0">
                                      <h6 className="card-title fw-bolder mb-3">Workshop Rate Details.</h6>
                                      <div className="spacer"></div>
                                      <form>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Rate (Rands Per Hour)</label>
                                            <input className="form-control" type="text"  defaultValue={workshopInfo.rate} onChange={(ev) => {
                                              workshopInfo.rate = ev.target.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Rate Discount</label>
                                            <input className="form-control w-50" type="text"  defaultValue={workshopInfo.rate_discount} onChange={(ev) => {
                                              workshopInfo.rate_discount = ev.target.value;
                                            }} />
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label fw-bolder">Parts Discount</label>
                                            <input className="form-control w-50" type="text"  defaultValue={workshopInfo.parts_discount} onChange={(ev) => {
                                              workshopInfo.parts_discount = ev.target.value;
                                            }} />
                                          </div>
                                      </form>
                                      <IonButton color="warning" size="small" onClick={() => {saveBusiness()}}>Save</IonButton>
                                  </div>
                              </div>

                              
{/*                             
                              <div className="card mb-3">
                                <div className="card-body p-0">
                                    <h6 className="card-title fw-bolder mb-3">Please upload your required documents.</h6>
                                    <div className="spacer"></div>
                                    <form>
                                      <div className="flex-main">
                                        <div className={mediaUploads.company_reg ? "upload-form-group uploaded" : "upload-form-group"} onClick={ (e) => {
                                            setActiveType('company_reg');
                                            setTimeout(() => {
                                              // @ts-ignore
                                              fileInput.current && fileInput.current.click()
                                            }, 500);
                                          }}>
                                            {
                                              loadingcompany_reg ? (
                                                <>
                                                  <IonSpinner name="crescent" color="warning" />
                                                  <h3 key="4" ><strong>Uploading - </strong>Company Registration (CIPC Certificate)</h3>
                                                </>
                                                
                                              ) : (
                                                !mediaUploads.company_reg ? (
                                                  <>
                                                    <img key="1" src={UploadIcon} style={{'height': '30px'}} />
                                                    <h3 key="2" >Company Registration (CIPC Certificate)</h3>
                                                  </>
                                                ) : (
                                                  <>
                                                    <img key="3" src={UploadedIcon} style={{'height': '30px'}} />
                                                    <h3 key="4" ><strong>Uploaded - </strong>Company Registration (CIPC Certificate)</h3>
                                                  </>
                                                )
                                              )
                                            }
                                        </div>
                                        {
                                          mediaUploads.company_reg ? (
                                            <IonButton color="primary" size="small" onClick={() => {
                                              window.open(mediaUploads.company_reg_url.url, '_blank')
                                            }}>View</IonButton>
                                          ) : null
                                        }
                                      </div>
                                      <>
                                        {
                                          company_reg.progress ? (
                                            <IonProgressBar type="indeterminate" color="warning" />
                                          ) : null
                                        }
                                        {
                                          company_reg.error ? (
                                            <p className="text-danger"><small>{company_reg.error}</small></p>
                                          ) : null
                                        }
                                      </>
                                        <div className="flex-main">
                                          <div className={mediaUploads.bank_letter ? "upload-form-group uploaded" : "upload-form-group"}  onClick={ (e) => {
                                            console.log(e);
                                            setActiveType('bank_letter');
                                            setTimeout(() => {
                                              // @ts-ignore
                                              fileInput.current && fileInput.current.click()
                                            }, 1000)
                                          }}>
                                            {
                                              !mediaUploads.bank_letter ? (
                                                <>
                                                  <img src={UploadIcon} style={{'height': '30px'}} />
                                                  <h3>Bank Verifcation Letter</h3>
                                                </>
                                              ) : (
                                                <>
                                                  <img src={UploadedIcon} style={{'height': '30px'}} />
                                                  <h3><strong>Uploaded - </strong>Bank Verifcation Letter</h3>
                                                </>
                                              )
                                            }
                                          </div>
                                            {
                                              mediaUploads.bank_letter ? (
                                                <IonButton color="primary" size="small" onClick={() => {
                                                  window.open(mediaUploads.bank_letter.url, '_blank')
                                                }}>View</IonButton>
                                              ) : null
                                            }
                                        </div>
                                          {
                                            bank_letter.progress ? (
                                              <IonProgressBar type="indeterminate" color="warning" />
                                            ) : null
                                          }
                                          {
                                            bank_letter.error ? (
                                              <p className="text-danger"><small>{bank_letter.error}</small></p>
                                            ) : null
                                          }
                                        <div className="flex-main">
                                          <div className={mediaUploads.proof_of_address ? "upload-form-group uploaded" : "upload-form-group"}  onClick={ (e) => {
                                            console.log(e);
                                            setActiveType('proof_of_address');
                                            setTimeout(() => {
                                              // @ts-ignore
                                              fileInput.current && fileInput.current.click()
                                            }, 1000)
                                          }}>
                                            {
                                              !mediaUploads.proof_of_address ? (
                                                <>
                                                  <img src={UploadIcon} style={{'height': '30px'}} />
                                                  <h3>Proof of Address</h3>
                                                </>
                                              ) : (
                                                <>
                                                  <img src={UploadedIcon} style={{'height': '30px'}} />
                                                  <h3><strong>Uploaded - </strong>Proof of Address</h3>
                                                </>
                                              )
                                            }
                                          </div>
                                            {
                                              mediaUploads.proof_of_address ? (
                                                <IonButton color="primary" size="small" onClick={() => {
                                                  window.open(mediaUploads.proof_of_address.url, '_blank')
                                                }}>View</IonButton>
                                              ) : null
                                            }
                                        </div>
                                          {
                                            proof_of_address.progress ? (
                                              <IonProgressBar type="indeterminate" color="warning" />
                                            ) : null
                                          }
                                          {
                                            proof_of_address.error ? (
                                              <p className="text-danger"><small>{proof_of_address.error}</small></p>
                                            ) : null
                                          }
                                        <div className="flex-main">
                                          <div className={mediaUploads.identification_document ? "upload-form-group uploaded" : "upload-form-group"}  onClick={ (e) => {
                                            console.log(e);
                                            setActiveType('identification_document');
                                            setTimeout(() => {
                                              // @ts-ignore
                                              fileInput.current && fileInput.current.click()
                                            }, 1000)
                                          }}>
                                            {
                                              !mediaUploads.identification_document ? (
                                                <>
                                                  <img src={UploadIcon} style={{'height': '30px'}} />
                                                  <h3>Principal Member Identification Document</h3>
                                                </>
                                              ) : (
                                                <>
                                                  <img src={UploadedIcon} style={{'height': '30px'}} />
                                                  <h3><strong>Uploaded - </strong>Principal Member Identification Document</h3>
                                                </>
                                              )
                                            }
                                          </div>
                                            {
                                              mediaUploads.identification_document ? (
                                                <IonButton color="primary" size="small" onClick={() => {
                                                  window.open(mediaUploads.identification_document.url, '_blank')
                                                }}>View</IonButton>
                                              ) : null
                                            }
                                        </div>
                                          {
                                            identification_document.progress ? (
                                              <IonProgressBar type="indeterminate" color="warning" />
                                            ) : null
                                          }
                                          {
                                            identification_document.error ? (
                                              <p className="text-danger"><small>{identification_document.error}</small></p>
                                            ) : null
                                          }
                                        <input ref={fileInput} className="hidden-input" type="file" onChange={(ev) =>
                                          //  handleFileInput(ev)
                                          console.log()
                                           } />
                                    </form>
                                </div>
                              </div>  */}
                            </div>
                          </div>
                        </div>

                    ) : (
                       <div style={{ height: "100%", width: "100%", "display": "flex", alignItems: 'center' }}>
                       <div className="k-loading-image" />
                       </div>

                  
                     ) 
                    }

              </div>
            </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Business;
