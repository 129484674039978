import { LIST, POST, PUT, GET, DELETE } from "./api.service" 

export const getSchedules = (id: string) => {
    return LIST(`schedule/${id}`);
}
export const createSchedule = (data: any) => {
    return POST(`schedules`, data);
}
export const updateSchedule = (data: any) => {
    return PUT(`schedule`, data);
}