import { LIST, POST, PUT, GET, DELETE } from "./api.service" 

export const saveOnboarding = (data: any) => {
    return POST(`onboarding/workshop`, data);
}
export const checkStatus = (id: string) => {
    return LIST(`onboarding/status/${id}`);
}
// export const getWorkshop = () => {
//     return LIST(`workshops`);
// }
export const putWorkshop = (id: string, data: any) => {
    return PUT(`workshop`, data);
}

export const viewBookings = () => {
    return GET("workshop/view-bookings", {});
}

export const updateBookingStatus = (data: any) => {
    return PUT(`workshop/update-booking-status`, data);
}