import {
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../../components/header/Header";
import StartModal from "../../../components/modals/StartModal";
import "./Workshop.scss";

import LandingImage from "../../assets/img/illustration-placeholder.svg";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getRmi } from "../../../services/rmi.service";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { WorkshopModel } from "../../../classes/business.class";
import {
  workshop,
  updateWorkshop,
  brands,
} from "../../../services/data.service";
import { putWorkshop } from "../../../services/workshop.service";

const Workshop: React.FC = () => {
  const history = useHistory();
  const [workshopInfo, setWorkshopInfo]: any = useState(null);
  const [vehicles, setVehicles]: any = useState(null);

  const saveWorkshop = () => {
    setWorkshopInfo(workshopInfo);
    putWorkshop(workshopInfo.workshop._id.$oid, workshopInfo.workshop)
      .then((d: any) => {
        setWorkshopInfo(workshopInfo);
        // getWorkshop().then((workshopData: any) => {
        //   updateWorkshop(workshopData);
        // });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    workshop.subscribe((workshopData: any) => {
      console.log(workshopData);
      if (workshopData) {
        setWorkshopInfo(workshopData);
      }
    });
    brands.subscribe((workshopData: any) => {
      setVehicles(workshopData);
    });
  }, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M11.0011006,0.545590002 C13.9323479,1.658448 16,4.35285243 16,7.5 C16,10.9943761 13.4509254,13.9305945 10.0009562,14.7634864 L10,16 L6,16 L6.00004783,14.7637288 C2.54956914,13.9311641 0,10.9947151 0,7.5 C0,4.35249362 2.06812357,1.65783362 4.99990199,0.545209426 L5,8 L11,8 L11.0011006,0.545590002 Z"
                      id="Combined-Shape"
                    ></path>
                  </svg>
                  <span>Workshop</span>
                </h6>
              </div>
              <div className="content-container grey-bg">
                {workshopInfo ? (
                  <>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <h6 className="card-title fw-bolder mb-0">
                            Select the vehicles your workshop is qualified to
                            service.
                          </h6>
                          <div className="spacer"></div>
                          <MultiSelect
                            autoClose={false}
                            placeholder="Search Make"
                            data={vehicles}
                            value={workshopInfo.workshop.vehicles}
                            filterable={true}
                            textField="name"
                            dataItemKey="name"
                            onChange={(ev) => {
                              workshopInfo.workshop.vehicles = ev.value;
                              saveWorkshop();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <h6 className="card-title fw-bolder mb-0">
                            Select the ages of the cars you work on
                          </h6>
                          <div className="spacer"></div>
                          <div className="form-control p-3 fill">
                            <p className="l-1 fw-light m-0 d-flex justify-content-between">
                              0 - 10 Years
                              <IonCheckbox
                                slot="end"
                                value={workshopInfo.workshop.newcars}
                                checked={workshopInfo.workshop.newcars}
                                mode="md"
                                color="warning"
                                onIonChange={(ev) => {
                                  workshopInfo.workshop.newcars =
                                    ev.detail.checked;
                                  saveWorkshop();
                                }}
                              ></IonCheckbox>
                            </p>
                          </div>
                          <div className="form-control p-3 fill">
                            <p className="l-1 fw-light m-0 d-flex justify-content-between">
                              10+ Years
                              <IonCheckbox
                                slot="end"
                                value={workshopInfo.workshop.oldcars}
                                checked={workshopInfo.workshop.oldcars}
                                mode="md"
                                color="warning"
                                onIonChange={(ev) => {
                                  workshopInfo.workshop.oldcars =
                                    ev.detail.checked;
                                  saveWorkshop();
                                }}
                              ></IonCheckbox>
                            </p>
                          </div>
                          <p className="mb-0">
                            <strong>NOTE:</strong> You may select more than one
                            option
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <h6 className="card-title fw-bolder mb-0">
                            Do you offer complimentary lifts to your customers?
                          </h6>
                          <div className="spacer"></div>
                          <div className="form-control p-3 fill">
                            <p className="l-1 fw-light m-0 d-flex justify-content-between">
                              No, we don't
                              <IonCheckbox
                                slot="end"
                                value={workshopInfo.workshop.nolift}
                                checked={workshopInfo.workshop.nolift}
                                mode="md"
                                color="warning"
                                onIonChange={(ev) => {
                                  if (workshopInfo.workshop.nolift) {
                                    workshopInfo.workshop.nolift =
                                      ev.detail.checked;
                                  } else {
                                    workshopInfo.workshop.nolift = ev.detail
                                      .checked
                                      ? true
                                      : false;
                                    workshopInfo.workshop.yeslift = ev.detail
                                      .checked
                                      ? false
                                      : true;
                                  }
                                  saveWorkshop();
                                }}
                              ></IonCheckbox>
                            </p>
                          </div>
                          <div className="form-control p-3 fill">
                            <p className="l-1 fw-light m-0 d-flex justify-content-between">
                              Yes, we do
                              <IonCheckbox
                                slot="end"
                                value={workshopInfo.workshop.yeslift}
                                checked={workshopInfo.workshop.yeslift}
                                mode="md"
                                color="warning"
                                onIonChange={(ev) => {
                                  if (workshopInfo.workshop.yeslift) {
                                    workshopInfo.workshop.yeslift =
                                      ev.detail.checked;
                                  } else {
                                    workshopInfo.workshop.yeslift = ev.detail
                                      .checked
                                      ? true
                                      : false;
                                    workshopInfo.workshop.nolift = ev.detail
                                      .checked
                                      ? false
                                      : true;
                                  }
                                  saveWorkshop();
                                }}
                              ></IonCheckbox>
                            </p>
                          </div>
                          {workshopInfo.workshop.yeslift ? (
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Max Travel Distance (One Way)
                              </label>
                              <div className="input-group w-50">
                                <input
                                  className="form-control"
                                  type="number"
                                  defaultValue={workshopInfo.workshop.distance}
                                  onChange={(ev) => {
                                    workshopInfo.workshop.distance =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                                <span className="input-group-text">Km</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <h6 className="card-title fw-bolder mb-0">
                            Set your default retail labour rate, and potential
                            discounts.
                          </h6>
                          <div className="spacer"></div>
                          <div className="row">
                            <div className="col">
                              <label className="form-label fw-bolder">
                                Labour rate (per hour)
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="5000"
                                  defaultValue={workshopInfo.workshop.rate}
                                  onChange={(ev) => {
                                    workshopInfo.workshop.rate =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <label className="form-label fw-bolder">
                                Discount
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="100"
                                  defaultValue={
                                    workshopInfo.workshop.rate_discount
                                  }
                                  onChange={(ev) => {
                                    workshopInfo.workshop.rate_discount =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                                <span className="input-group-text">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <label className="form-label fw-bolder">
                                Discount on parts
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="100"
                                  defaultValue={
                                    workshopInfo.workshop.parts_discount
                                  }
                                  onChange={(ev) => {
                                    workshopInfo.workshop.parts_discount =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                                <span className="input-group-text">%</span>
                              </div>
                            </div>
                          </div>
                          <button
                            className="pt-3 btn btn-sm btn-link"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                          >
                            What does it mean?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                          <h6 className="card-title fw-bolder mb-0">
                            How many workshop hours per day would you like to
                            allocate to bookings from Servisor?
                          </h6>
                          <div className="spacer"></div>
                          <div className="row">
                            <div className="col-6">
                              <label className="form-label fw-bolder">
                                Allocated hours per day
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="8"
                                  defaultValue={
                                    workshopInfo.workshop.workshop_hours
                                  }
                                  onChange={(ev) => {
                                    workshopInfo.workshop.workshop_hours =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                                <span className="input-group-text">Hrs</span>
                              </div>
                            </div>
                            <div className="col-6">
                              <label className="form-label fw-bolder">
                                Slots Per Hour
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="100"
                                  value={workshopInfo.workshop.slots_per_hour}
                                  onChange={(ev) => {
                                    workshopInfo.workshop.slots_per_hour =
                                      ev.target.value;
                                    saveWorkshop();
                                  }}
                                />
                                <span className="input-group-text">Slots</span>
                              </div>
                            </div>
                          </div>
                          <button
                            className="pt-3 btn btn-sm btn-link"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                          >
                            What does it mean?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <h6 className="card-title fw-bolder mb-0">
                            What are your workshop operating hours?
                          </h6>
                          <div className="spacer"></div>
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Monday - Friday
                            </label>
                            <IonToggle
                              mode="ios"
                              checked={workshopInfo.workshop.weekdays}
                              onIonChange={(ev) => {
                                workshopInfo.workshop.weekdays =
                                  ev.detail.checked;
                                saveWorkshop();
                              }}
                            ></IonToggle>
                          </div>
                          {workshopInfo.workshop.weekdays === true ? (
                            <>
                              <div className="row">
                                <div className="col">
                                  <div className="input-group w-100">
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={
                                        workshopInfo.workshop.weekdays_start
                                      }
                                      onIonChange={(ev: any) => {
                                        workshopInfo.workshop.weekdays_start =
                                          ev.target.value;
                                        saveWorkshop();
                                      }}
                                    ></IonDatetime>
                                    <span className="input-group-text">to</span>
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={workshopInfo.workshop.weekdays_end}
                                      onIonChange={(ev: any) => {
                                        const r = ev.target.value;
                                        workshopInfo.workshop.weekdays_end =
                                          ev.target.value;
                                        saveWorkshop();
                                      }}
                                    ></IonDatetime>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <hr />
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Saturday
                            </label>
                            <IonToggle
                              mode="ios"
                              checked={workshopInfo.workshop.saturday}
                              onIonChange={(ev) => {
                                workshopInfo.workshop.saturday =
                                  ev.detail.checked;
                                saveWorkshop();
                              }}
                            ></IonToggle>
                          </div>
                          {workshopInfo.workshop.saturday === true ? (
                            <>
                              <div className="row">
                                <div className="col">
                                  <div className="input-group w-100">
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={workshopInfo.workshop.sat_start}
                                      onIonChange={(ev: any) => {
                                        workshopInfo.workshop.sat_start =
                                          ev.target.value;
                                        saveWorkshop();
                                      }}
                                    ></IonDatetime>
                                    <span className="input-group-text">to</span>
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={workshopInfo.workshop.sat_end}
                                      onIonChange={(ev: any) => {
                                        workshopInfo.workshop.sat_end =
                                          ev.target.value;
                                        saveWorkshop();
                                      }}
                                    ></IonDatetime>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <hr />
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Sunday
                            </label>
                            <IonToggle
                              mode="ios"
                              checked={workshopInfo.workshop.sunday}
                              onIonChange={(ev) => {
                                workshopInfo.workshop.sunday =
                                  ev.detail.checked;
                                saveWorkshop();
                              }}
                            ></IonToggle>
                          </div>
                          {workshopInfo.workshop.sunday === true ? (
                            <div className="row">
                              <div className="col">
                                <div className="input-group w-100">
                                  <IonDatetime
                                    displayFormat="h:mm A"
                                    pickerFormat="h:mm A"
                                    value={workshopInfo.workshop.sun_start}
                                    onIonChange={(ev: any) => {
                                      workshopInfo.workshop.sun_start =
                                        ev.target.value;
                                      saveWorkshop();
                                    }}
                                  ></IonDatetime>
                                  <span className="input-group-text">to</span>
                                  <IonDatetime
                                    displayFormat="h:mm A"
                                    pickerFormat="h:mm A"
                                    value={workshopInfo.workshop.sun_end}
                                    onIonChange={(ev: any) => {
                                      workshopInfo.workshop.sun_end =
                                        ev.target.value;
                                      saveWorkshop();
                                    }}
                                  ></IonDatetime>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Workshop;
