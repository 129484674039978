import { IonContent, IonPage } from "@ionic/react";
import Header from "../../../components/header/Header";

import { useEffect } from "react";

import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/Workshop_Hero_Right.json";
import AdminSignInForm from "../../../forms/AdminSignInForm";

const AdminSignin: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
          <div className="container">
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
                <div style={{ padding: "50px" }}>
                  <Lottie options={defaultOptions} />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 ">
                <AdminSignInForm />
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default AdminSignin;
