import { IonContent, IonPage } from "@ionic/react";
import Header from "../../../components/header/Header";
import StartModal from "../../../components/modals/StartModal";
import "./Forgot.scss";

import ForgotForm from "../../../forms/ForgotForm";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/Workshop_Hero_Right.json";

const Forgot: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
          <div className="container">
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
                <div style={{ padding: "50px" }}>
                  <Lottie options={defaultOptions} />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 ">
                <ForgotForm />
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Forgot;
