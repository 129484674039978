import { IonActionSheet, IonButton, IonContent, IonModal, IonPage } from "@ionic/react";
import StartModal from "../../../components/modals/StartModal";
import "./ManageWorkshops.scss";
import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { workshop as activeItem } from "../../../services/data.service";

import {
  Grid,
  GridColumn as Column,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  getAdminWorkshops,
  // updateAdminWorkshop,
  deactivateWorkshop,
  activateWorkshop
} from "../../../services/admin.service";
import { ConnectableObservable } from "rxjs";


const ManageWorkshops: React.FC = () => {
  const [workshops, setWorkshops]:any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState([]);
  
  const [showActionSheet, setShowActionSheet]: any = useState(false);
  const [activeitem, setActiveitem]: any = useState(null);

  const data = {
    "data": [
        {
            "Created_date": "Fri, 20 Oct 2023 07:23:03 GMT",
            "RMI": 564546456,
            "Updated_dated": [],
            "_id": "860b5e8372934ef7a07137dec801a7a0",
            "active": true,
            "address": "somewhere in mexico",
            "bank": "Capitect",
            "bank_account_no": "1535325435",
            "bank_account_type": "checque",
            "bank_branch_code": "453243",
            "bookings": [
                {
                    "Created_Date": "Fri, 20 Oct 2023 07:23:03 GMT",
                    "Updated_dated": [],
                    "_id": "61ea436f473f49968613f86a5c41f5df",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "luke@templeman.co.za",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "Rejected",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "860b5e8372934ef7a07137dec801a7a0"
                }
            ],
            "email": "luke.templemaan@injozi.biz",
            "email_confirmation": true,
            "latlng": "24.2.3, 2323",
            "name": "test",
            "parts_discount": "5%",
            "password": "0b14d501a594442a01c6859541bcb3e8164d183d32937b851835442f69d5c94e",
            "phone_number": "0682033442",
            "province": "Gauteng",
            "rate": "12%",
            "rate_discount": "13%",
            "reg_no": "203434",
            "role": "workshop",
            "surname": "tesst",
            "vat_no": "43t3fgesrg",
            "vehicles": [],
            "workshop_name": "Cales best workshop"
        },
        {
            "Created_date": "Sat, 21 Oct 2023 14:02:51 GMT",
            "RMI": "vddrg",
            "Updated_dated": [],
            "_id": "187e58803fe04c3e8bf61eb82698b9a4",
            "active": true,
            "address": "Eastgate Shopping Centre, Bradford Road, Bedfordview, Germiston, South Africa",
            "bank": {
                "id": 3,
                "name": "Capitec"
            },
            "bank_account_no": "ffsef",
            "bank_account_type": {
                "id": 2,
                "name": "Savings"
            },
            "bank_branch_code": "23434",
            "bookings": [
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:07:53 GMT",
                    "Updated_dated": [],
                    "_id": "573f6f56c35c4d71bf0ea503b3736c83",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:25:14 GMT",
                    "Updated_dated": [],
                    "_id": "3758953831b8465ca76798c59d900c06",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:26:41 GMT",
                    "Updated_dated": [],
                    "_id": "80720a3826f442ce882e826cea23ebd7",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:28:37 GMT",
                    "Updated_dated": [],
                    "_id": "91e5e77b61944bc48ffe77ca5b9afe69",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:29:42 GMT",
                    "Updated_dated": [],
                    "_id": "10c7719285d44f9c8e44a10d6a1e90fe",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:30:40 GMT",
                    "Updated_dated": [],
                    "_id": "08b9cb5470f546758b17c57401a4f12c",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:32:31 GMT",
                    "Updated_dated": [],
                    "_id": "12cf5ff47631478f931757c77c881b77",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:32:31 GMT",
                    "Updated_dated": [],
                    "_id": "12cf5ff47631478f931757c77c881b77",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:34:40 GMT",
                    "Updated_dated": [],
                    "_id": "e41ea1dec69b4695b44c6821043a5f04",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:38:02 GMT",
                    "Updated_dated": [],
                    "_id": "cb7a877cb9c444788288d02b9fcd35b2",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:41:31 GMT",
                    "Updated_dated": [],
                    "_id": "7ce863ea852849c19c914f684a10b53c",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:45:26 GMT",
                    "Updated_dated": [],
                    "_id": "67192cabcff34e8da15ff63615905c3c",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                },
                {
                    "Created_Date": "Thu, 26 Oct 2023 08:48:26 GMT",
                    "Updated_dated": [],
                    "_id": "5a7a5f85d2a84afc9c3ea252cd91d745",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "john.doe@example.com",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "pending",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
                }
            ],
            "email": "luke.templeman@injozi.biz",
            "email_confirmation": true,
            "latlng": [
                -26.180388,
                28.1172088
            ],
            "name": "Luke",
            "new_cars": "true",
            "old_cars": "true",
            "parts_discount": "6",
            "password": "03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4",
            "phone_number": "0474747",
            "province": {
                "id": 3,
                "name": "Gauteng"
            },
            "rate": "2",
            "rate_discount": "6",
            "reg_no": "erg",
            "role": "workshop",
            "surname": "sTee",
            "vat_no": "rew",
            "vehicles": [
                {
                    "logo": "https://www.car-logos.org/wp-content/uploads/2011/09/aixam.png",
                    "name": "Aixam"
                },
                {
                    "logo": "https://www.car-logos.org/wp-content/uploads/2011/09/acura.png",
                    "name": "Acura"
                }
            ],
            "workshop_name": "Luke"
        }
    ],
    "msg": "Workshops data"
}

  // generateInvoice
  // updateInvoice

  useEffect(() => {
    activeItem.subscribe((workShopDetails: any) => {
      console.log(workShopDetails);
      if (workShopDetails) {
        setWorkshopInfo(workShopDetails);
      }
      getAllWorkshops();
    });
  }, []);

  const getAllWorkshops = () => {
    // getAdminWorkshops({}).then((adminDetails: any) => {
    //   console.log(adminDetails, "adminDetails")
    //   setWorkshops(adminDetails);
    // });
    setWorkshops(data)
  };

  const showAction = (data: any) => {
    setActiveitem(data);
    setShowActionSheet(true);
  };

  const enableWorkshop = (data: any) => {
    activateWorkshop({id:data}).then((response: any) => {
      console.log(response, "response")
      getAllWorkshops()
    });

  };

  const disableWorkshop = (data: any) => {
    deactivateWorkshop({id:data}).then((response: any) => {
      console.log(response, "response")
      getAllWorkshops()
    })
  }


  useEffect(() => {}, []);

  const BooleanCell = (props: GridCellProps) => {
    return (
      <td>
        {props.dataItem[props.field || 0]
          ? <div style={{background:"green", textAlign:"center", color:"white", width:"100%", height:"100%"}}>Enabled</div>
          : <div style={{background:"red" , textAlign:"center", color:"white", width:"100%", height:"100%" }}>Disabled</div>}
      </td>
    );
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-building"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    ></path>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                  </svg>
                  <span>Manage Workshops</span>
                </h6>
              </div>
              <div className="content-container">
{/* 
              <IonModal isOpen={showActionSheet}>
      <div>
        <h2>Workshop Details</h2>
        <p>Name: {activeItem.workshop_name}</p>
        <p>RMI: {activeItem.RMI}</p>
        <p>Email: {activeItem.email}</p>
        <p>Phone: {activeItem.phone_number}</p>
        <p>Address: {activeItem.address}</p>
        <p>Province: {activeItem.province}</p>
        <p>Status: {activeItem.active ? "Enabled" : "Disabled"}</p>
      </div>
      <div>
        <IonButton onClick={() => enableWorkshop(activeitem._id)}>Enable</IonButton>
        <IonButton onClick={() => disableWorkshop(activeitem._id)}>Disable</IonButton>
        <IonButton onClick={setActiveitem(null)}>Close</IonButton>
      </div>
    </IonModal> */}

               <IonActionSheet
                  isOpen={showActionSheet}
                  onDidDismiss={() => setShowActionSheet(false)}
                  mode="md"
                  cssClass="my-custom-class"
                  buttons={[
                    {
                      text: "Enable",
                      handler: () => {
                        if (activeitem.status !== "approved") {
                          activeitem.status = "approved";
                          console.log(activeitem._id, "activeiddtem")
                          setActiveitem(activeitem);
                          enableWorkshop(String(activeitem._id));
                        }
                        console.log("Enable clicked");
                      },
                    },
                    {
                      text: "Disable",
                      handler: () => {
                        if (activeitem.status !== "rejected") {
                          activeitem.status = "rejected";
                          setActiveitem(activeitem);
                          console.log(activeitem._id, "activeiddtem")
                          disableWorkshop(String(activeitem._id));
                        }
                      }, 
                    },
                    {
                      text: "Cancel",
                      role: "cancel",
                      handler: () => {
                        setActiveitem(null);
                      },
                    },
                  ]}
                ></IonActionSheet> 
                {/* {workshops ? ( */}
                  <Grid
                    style={{ height: "100%" }}
                    data={workshops}
                    sortable={true}
                  >
                  <Column field="workshop_name" title="Name" />
                  <Column field="RMI" title="RMI" />
                  <Column field="email" title="Email" />
                  <Column field="phone_number" title="Phone" />
                  {/* <Column field="address" title="Address" /> */}
                  <Column
                    field="address"
                    title="Address"
                    cell={(props: GridCellProps) => (
                      <td style={{ textAlign: "right" }}>
                        <span className="service-status-cell">
                          {props.dataItem.address}
                        </span>
                      </td>
                    )}
                  />
                  <Column field="province" title="Province" />
                  <Column field="active" title="Status" cell={BooleanCell}/>

                  
                  <Column
                    title="Options"
                    cell={(props: GridCellProps) => (
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            showAction(props.dataItem);
                          }}
                        >
                          Review
                        </button>
                      </td>
                    )}
                    />
                  </Grid>
                {/* ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default ManageWorkshops;
