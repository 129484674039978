import React from 'react';
import "../modals/ModelStyling.scss"
import { updateBookingStatus } from '../../services/workshop.service';

const DashModal:  React.FC<any>= ({handleShowSnackbar,setSnackbarMessage, modaldata, onClose  }) => {

   // Function to handle the button click and trigger the API
   const handleStatusUpdate = (status:any) => {
    // Make your API call here, passing the selected status
    // updateStatus(status);



    // updateBookingStatus({"booking_id": modaldata._id, "status": status}).then((res) => {
    //   console.log(res);
    //   setSnackbarMessage(res)
    //   handleShowSnackbar(true)
    // }).catch((err) => {
    //   console.log(err);
    // })

    // Close the modal or perform any other necessary actions
    onClose();
  };

  return (
    <div className="modal"> {/* Apply the 'modal' class */}
    <div className="modal-height">
    <div className="modal-content"> {/* Apply the 'modal-content' class */}
      <h2>Vehicle Details</h2>
        <p>Make: {modaldata.vehicle_details.make}</p>
        <p>Model: {modaldata.vehicle_details.model}</p>
        <p>Year: {modaldata.vehicle_details.year}</p>
        <p>Registration: {modaldata.vehicle_details.registration_number}</p>
        <h2>Customer Details</h2>
        <p>Name: {modaldata.name} {modaldata.surname}</p>
        <p>Email: {modaldata.email}</p>
        <p>Phone Number: {modaldata.phone_number}</p>
        <p>Address: {modaldata.address}</p>
        <p>Area Code: {modaldata.area_code}</p>
        <p>Province: {modaldata.province}</p>
        <h2>Part Details</h2>
        <p>Engine Capacity: {modaldata.vehicle_details.engine_capacity}</p>
        <p>Gearbox Type: {modaldata.vehicle_details.gearbox_type}</p>
        <p>Mileage: {modaldata.vehicle_details.milleage}</p>
        <p>Original Parts: {modaldata.original_parts.join(', ')}</p>
        <p>Parts Needed: {modaldata.parts_needed.join(', ')}</p>
        <h2>Service Details</h2>
        <p>Service Date: {modaldata.service_date}</p>
        <p>Service Plan: {modaldata.service_plan}</p>
        <p>Status: {modaldata.status}</p>
        <h2>Select Booking Status</h2>
    


        <div className="modal-header"> {/* Create a header section for the buttons */}
        <button
          style={{ background: "#FFC300" }}
          className="modal-button"
          onClick={() => handleStatusUpdate("In Progress")}
        >
          In Progress
        </button>
        <button
          style={{ background: "#30A029" }}
          className="modal-button"
          onClick={() => handleStatusUpdate("Completed")}
        >
          Completed
        </button>
        <button
          style={{ background: "#C70039" }}
          className="modal-button"
          onClick={() => handleStatusUpdate("Rejected")}
        >
          Rejected
        </button>
        <button
          className="modal-button"
          onClick={() => handleStatusUpdate("Pending")}
        >
          Pending
        </button>
        </div>
        <p className="disclaimer">
          Selecting an option above will send an update email to the customer.
        </p>

        

        <button className="modal-close-button" onClick={onClose}>Close</button> {/* Apply a custom class for the close button */}
      </div>
    </div>

    </div>
  );
};


export default DashModal;