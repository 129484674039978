import React, { useState } from 'react';
import './CustomSnackbar.scss';


const CustomSnackbar: React.FC<any> = ({ message, onClose }) => {
    const [open, setOpen] = useState(true);
  
    const handleClose = () => {
      setOpen(false);
      onClose();
    };
  
    return (
      <div className={`custom-snackbar ${open ? 'show' : 'hide'}`}>
        <div className="message">{message}</div>
        <button className="close-button" onClick={handleClose}>
          Close
        </button>
      </div>
    );
  };
  
  export default CustomSnackbar;