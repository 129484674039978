import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../components/header/Header";
import StartModal from "../../components/modals/StartModal";
import "./NotFound.scss";

import LandingImage from "../../assets/img/illustration-placeholder.svg";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getRmi } from "../../services/rmi.service";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  const history = useHistory();
  const [rmiNumber, setRmiNumber] = useState("");

  useEffect(() => {}, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <div>
            <h1>404 - Not Found!</h1>
            <Link to="/diary">Go Home</Link>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default NotFound;
