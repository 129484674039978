import {
    IonContent,
    IonHeader,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import Header from "../../../components/header/Header";
  import StartModal from "../../../components/modals/StartModal";
  import "./ManageBookings.scss";
  
  import LandingImage from "../../assets/img/illustration-placeholder.svg";
  import { useHistory } from "react-router";
  import { useCallback, useEffect, useState } from "react";
  import DashHeader from "../../../components/dash-header/DashHeader";
  import LeftMenu from "../../../components/left-menu/LeftMenu";
  
  import moment from "moment";
  
  import {
    Grid,
    GridColumn as Column,
    GridCellProps,
    GridSortChangeEvent,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
  } from "@progress/kendo-react-grid";
  
  import { getServices } from "../../../services/services.service";
  import {
    orderBy,
    SortDescriptor,
    DataResult,
    process,
    State,
  } from "@progress/kendo-data-query";
  import { workshop } from "../../../services/data.service";
  import AdminDashModal from "../../../components/modals/AdminBookingContent";
  
  import {
    setExpandedState,
    setGroupIds,
  } from "@progress/kendo-react-data-tools";
  import { viewBookings } from "../../../services/workshop.service";
  import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";
import { getAllBookingsAPI, getWorkshopById } from "../../../services/admin.service";
  
  const initialDataState: State = {
    take: 10,
    skip: 0,
    group: [{ field: "service_status" }],
  };
  
  const processWithGroups = (data: any[], dataState: State) => {
    const newDataState = process(data, dataState);
  
    setGroupIds({ data: newDataState.data, group: dataState.group });
  
    return newDataState;
  };
  
  const ManageBookings: React.FC = () => {


    const data = {
        "data": [
            {
                "Created_Date": "Wed, 25 Oct 2023 09:09:10 GMT",
                "Updated_dated": [],
                "_id": "4f5347cceaba45faa738864a78e1bc9d",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": true,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "rejected",
                "surname": "Athenkozi",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "860b5e8372934ef7a07137dec801a7a0"
            },
            {
                "Created_Date": "Wed, 25 Oct 2023 09:12:05 GMT",
                "Updated_dated": [],
                "_id": "aef4a44bfe844eeabf7d9c7156d4a280",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": true,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "860b5e8372934ef7a07137dec801a7a0"
            },
            {
                "Created_Date": "Wed, 25 Oct 2023 12:19:19 GMT",
                "Updated_dated": [],
                "_id": "b42bbf8d6d564f5e8685d485923c9680",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": true,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "In Progress",
                "surname": "Athenkozi",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Wed, 25 Oct 2023 12:22:53 GMT",
                "Updated_dated": [],
                "_id": "d86a69d4ea8d4e38b01a11bc404c6a14",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": true,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "Rejected",
                "surname": "Athenkozi",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:06:50 GMT",
                "Updated_dated": [],
                "_id": "066568fd77d14beca075f7647354a31c",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:14:10 GMT",
                "Updated_dated": [],
                "_id": "662fb9003a2540b1b386afa7307301c2",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:15:25 GMT",
                "Updated_dated": [],
                "_id": "a0dd9984e9bc4d2dab752c21f6d5a09e",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:17:26 GMT",
                "Updated_dated": [],
                "_id": "c52ca22fa52249169ae1ac44d1a2fc4f",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:19:11 GMT",
                "Updated_dated": [],
                "_id": "e9c63cd3353246908b8bcc7968b06a01",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:20:27 GMT",
                "Updated_dated": [],
                "_id": "403db76a422b46fd80e8e6308ff8269d",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:22:20 GMT",
                "Updated_dated": [],
                "_id": "a53f471b922a48509d37033af8dcc64e",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdfdfdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:29:18 GMT",
                "Updated_dated": [],
                "_id": "162869c187734e6487b5d7506ef27bb8",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdfdfdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:36:54 GMT",
                "Updated_dated": [],
                "_id": "2078ef0152d54a2cbe9c24b9723ca15e",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdfdfdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:40:24 GMT",
                "Updated_dated": [],
                "_id": "8bc1cd8ce0734a3a89a54adf901c6a92",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdfdfdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            },
            {
                "Created_Date": "Thu, 26 Oct 2023 22:41:59 GMT",
                "Updated_dated": [],
                "_id": "0559325aaa4645c68c0b331cabc14d35",
                "address": "yes",
                "area_code": "12345",
                "distance": 50,
                "email": "john.doe@example.com",
                "labour": 100,
                "latlng": "40.7128,-74.0060",
                "market_price": 500,
                "milleage": 50000,
                "name": "John",
                "original_parts": [
                    "part1",
                    "part2"
                ],
                "paid": false,
                "parts_needed": [
                    "part3",
                    "part4"
                ],
                "phone_number": "0682038042",
                "province": "Your Province",
                "registration_numer": "ABC123",
                "service_date": "2023-10-12",
                "service_plan": "Gold",
                "status": "pending",
                "surname": "Athenkozi",
                "uuid": "dwdwdfdfdwdwd",
                "vehicle_details": {
                    "engine_capacity": "Placeholder Engine Capacity",
                    "equipment": [],
                    "gearbox_type": "Placeholder Gearbox Type",
                    "make": "Car Make",
                    "milleage": 50000,
                    "model": "Car Model",
                    "registration_number": "ABC123"
                },
                "workshop_id": "187e58803fe04c3e8bf61eb82698b9a4"
            }
        ],
        "msg": "Bookings data"
    }

    const [bookings, setBookings]: any = useState([]);
    const [loadingServices, setLoadingServices]: any = useState(false);
    const [showModal, setShowModal]: any = useState(false);
    const [modaldata, setModaldata]: any = useState(false);
    const [workshopInfo, setWorkshop]: any = useState(null);
    const [workshopDetails, setWorkshopDetails]: any = useState(null);
    const [sort, setSort] = useState<Array<SortDescriptor>>([
      { field: "services_info.service_date", dir: "asc" },
    ]);
  
    const [dataState, setDataState] = useState<State>(initialDataState);
    const [resultState, setResultState] = useState<DataResult>(
      processWithGroups([], initialDataState)
    );
    const [collapsedState, setCollapsedState] = useState<string[]>([]);
    const onDataStateChange = useCallback((event: GridDataStateChangeEvent) => {
      const newDataState = processWithGroups(bookings, event.dataState);
  
      setDataState(event.dataState);
      setResultState(newDataState);
    }, []);
  
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
  
    const onExpandChange = useCallback(
      (event: GridExpandChangeEvent) => {
        const item = event.dataItem;
  
        if (item.groupId) {
          const newCollapsedIds = !event.value
            ? [...collapsedState, item.groupId]
            : collapsedState.filter((groupId) => groupId !== item.groupId);
          setCollapsedState(newCollapsedIds);
        }
      },
      [collapsedState]
    );
  
    // const newData = setExpandedState({
    //   data: resultState.data,
    //   collapsedIds: collapsedState,
    // });
  
    useEffect(() => {
     
        // getAllBookingsAPI({}).then((res: any) => {
        //   console.log(res.data);
        //   setBookings(res.data);
        // });
        setBookings(data.data)
    }, []);
  
    const sortChange = (event: GridSortChangeEvent) => {
      setBookings(getProducts(event.sort));
      setSort(event.sort);
    };
  
    const getProducts = (sort: SortDescriptor[]): any[] => {
      return orderBy(bookings, sort);
    };
  
    const titleCaseWord = (word: string) => {
      if (!word) return word;
      return word[0].toUpperCase() + word.substr(1).toLowerCase();
    };
  
    const getAllServices = (id: string) => {
      setLoadingServices(true);
      // getServices(id).then(async (serviceResponse: any) => {
      //   const arrayOfBookings: any[] = await [];
      //   await serviceResponse.forEach((data: any) => {
      //     const serviceData = Object.assign({}, data);
      //     serviceData.services_info.service_date = new Date(
      //       data.services_info.service_date.$date
      //     );
      //     serviceData.services_info.service_date = moment(
      //       serviceData.services_info.service_date,
      //       "YYYY-MM-DD",
      //       true
      //     ).format("dddd Do MMM");
      //     serviceData.fullname =
      //       serviceData.user.name + " " + serviceData.user.surname;
      //     serviceData.service_status = titleCaseWord(serviceData.service_status);
      //     arrayOfBookings.push(serviceData);
      //   });
      //   await console.log(arrayOfBookings);
      //   await setBookings(arrayOfBookings);
      //   await setTimeout(
      //     () =>
      //       setResultState(processWithGroups(arrayOfBookings, initialDataState)),
      //     1000
      //   );
      //   setLoadingServices(false);
      // });
    };
  
    
    const handleShowSnackbar = () => {
    //   setSnackbarMessage('Hello, Lord BreadWinner!');
      setShowSnackbar(true);
    };
  
    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    };
  
    const ShowModal = (data: any) => {
      setModaldata(data);
      console.log(data)
    //   getWorkshopById({}, data.workshop_id).then((res) => {
    //     console.log(res, "yee");
       
    //     setWorkshopDetails(res.data)
        
    //     // handleShowSnackbar(true)
    //     }).catch((err) => {
    //       console.log(err, "err")
    //       alert("Something went wrong, Please contact support")
    //     }
    //     )

    const workshopData = {
        "data": {
            "Created_date": "Fri, 20 Oct 2023 07:23:03 GMT",
            "RMI": 564546456,
            "Updated_dated": [],
            "_id": "860b5e8372934ef7a07137dec801a7a0",
            "active": true,
            "address": "somewhere in mexico",
            "bank": "Capitect",
            "bank_account_no": "1535325435",
            "bank_account_type": "checque",
            "bank_branch_code": "453243",
            "bookings": [
                {
                    "Created_Date": "Fri, 20 Oct 2023 07:23:03 GMT",
                    "Updated_dated": [],
                    "_id": "61ea436f473f49968613f86a5c41f5df",
                    "address": "yes",
                    "area_code": "12345",
                    "distance": 50,
                    "email": "luke@templeman.co.za",
                    "labour": 100,
                    "latlng": "40.7128,-74.0060",
                    "market_price": 500,
                    "milleage": 50000,
                    "name": "John",
                    "original_parts": [
                        "part1",
                        "part2"
                    ],
                    "parts_needed": [
                        "part3",
                        "part4"
                    ],
                    "phone_number": "0682038042",
                    "province": "Your Province",
                    "registration_numer": "ABC123",
                    "service_date": "2023-10-12",
                    "service_plan": "Gold",
                    "status": "Rejected",
                    "surname": "Athenkozi",
                    "vehicle_details": {
                        "engine_capacity": "Placeholder Engine Capacity",
                        "equipment": [],
                        "gearbox_type": "Placeholder Gearbox Type",
                        "make": "Car Make",
                        "milleage": 50000,
                        "model": "Car Model",
                        "registration_number": "ABC123"
                    },
                    "workshop_id": "860b5e8372934ef7a07137dec801a7a0"
                }
            ],
            "email": "luke.templemaan@injozi.biz",
            "email_confirmation": true,
            "latlng": "24.2.3, 2323",
            "name": "test",
            "parts_discount": "5%",
            "password": "0b14d501a594442a01c6859541bcb3e8164d183d32937b851835442f69d5c94e",
            "phone_number": "0682033442",
            "province": "Gauteng",
            "rate": "12%",
            "rate_discount": "13%",
            "reg_no": "203434",
            "role": "workshop",
            "surname": "tesst",
            "vat_no": "43t3fgesrg",
            "vehicles": [],
            "workshop_name": "Cales best workshop"
        },
        "msg": "Workshop found"
    }

    setWorkshopDetails(workshopData.data)
      setShowModal(true);
    };
  
    const modalEevent = (data: any) => {
      setModaldata(null);
      setShowModal(false);
      getAllServices(workshopInfo._id.$oid);
    };
  
    return (
      <>
        <IonPage>
          <IonContent fullscreen>
            <DashHeader />
            <div className="container-section">
              <LeftMenu />
              <div className="right-content-section">
                <div className="header-container">
                  <h6>
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 16 16"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M8,0 C9.36170213,0 10.7477204,0.0972644377 11.9635258,0.267477204 C12.5957447,0.364741641 13.1306991,0.924012158 13.2036474,1.04559271 L14.1762918,3.8449848 L15.6352584,4.06382979 C15.8054711,4.0881459 16,4.23404255 16,4.40425532 L16,4.76899696 C16,4.93920973 15.8541033,5.08510638 15.6838906,5.08510638 L14.8328267,5.08510638 C15.0030395,5.23100304 15.7082067,5.71732523 15.7325228,7.39513678 L15.7325228,12.6018237 L15.7325228,12.6018237 C15.7325228,12.8693009 15.5379939,13.0881459 15.2705167,13.0881459 L13.1793313,13.0881459 C12.9361702,13.0881459 12.7173252,12.8693009 12.7173252,12.6018237 L12.7173252,11.0668693 L12.7173252,11.0668693 C11.1367781,11.1641337 9.58054711,11.212766 8,11.1884498 C6.41945289,11.212766 4.83890578,11.1641337 3.28267477,11.0668693 L3.28267477,12.6018237 L3.28267477,12.6018237 C3.28267477,12.8693009 3.06382979,13.0881459 2.82066869,13.0881459 L0.729483283,13.0881459 C0.462006079,13.0881459 0.243161094,12.8693009 0.243161094,12.6018237 C0.243161094,12.0243161 0.247382641,11.2689759 0.252307779,10.4822378 L0.258330519,9.53245314 C0.263255657,8.74402651 0.267477204,7.98277609 0.267477204,7.39513678 C0.291793313,5.71732523 0.996960486,5.23100304 1.14285714,5.08510638 L0.291793313,5.08510638 C0.145896657,5.08510638 0,4.93920973 0,4.76899696 L0,4.40425532 C0,4.23404255 0.194528875,4.0881459 0.364741641,4.06382979 L1.82370821,3.8449848 L2.79635258,1.04559271 C2.86930091,0.924012158 3.37993921,0.364741641 4.01215805,0.267477204 C5.25227964,0.0972644377 6.61398176,0 8,0 Z M5,7 L2,7 L2,9 L5,9 L5,7 Z M14,7 L11,7 L11,9 L14,9 L14,7 Z"
                        id="Combined-Shape"
                      ></path>
                    </svg>
                    <span>Bookings</span>
                  </h6>
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      aria-expanded="false"
                      data-bs-toggle="dropdown"
                      type="button"
                    >
                      Next 7 Days 
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Next 7 Days
                      </a>
                      <a className="dropdown-item" href="#">
                        Next 14 Days
                      </a>
                      <a className="dropdown-item" href="#">
                        Next 30 Days
                      </a>
                    </div>
                  </div>
                </div>
                <div className="content-container">
                  {/* {loadingServices ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="k-loading-image" />
                    </div>
                  ) 
                  : bookings ? 
                  (
  
                    bookings.length > 0 ? ( */}
  
                  <Grid
                    style={{ height: "100%" }}
                    sortable={false}
                    onRowClick={(e: any) => {
                      console.log(e.dataItem.workshop_id, "yeehaw");
                 
                      ShowModal(e.dataItem);

                    }}
                    pageable={{ pageSizes: true }}
                    data={bookings}
                    total={resultState.total}
                    groupable={true}
                    onDataStateChange={onDataStateChange}
                    {...dataState}
                    onExpandChange={onExpandChange}
                    expandField="expanded"
                  >
                    <Column field="_id" title="ID" />
                    <Column field="surname" title="Name" />
                    <Column field="vehicle_details.make" title="Make" />
                    <Column field="vehicle_details.model" title="Model" />
                    <Column
                      field="vehicle_details.registration_number"
                      title="Registration"
                    />
                    <Column field="service_date" title="Service" />
                    <Column field="status" title="Status" />
                    <Column field="paid" title="Completed Payment?" />
                  </Grid>
                  {/* ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h6>No Bookings to show</h6>
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="k-loading-image" />
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            {showModal ? (
              <AdminDashModal
                handleShowSnackbar={handleShowSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                modaldata={modaldata}
                id={modaldata.workshop_id}
                onClose={() => setShowModal(false)}
                payment={modaldata.paid}
                workshopDetails={workshopDetails}
              />
            ) : null}
               {showSnackbar && (
          <CustomSnackbar message={snackbarMessage} onClose={handleCloseSnackbar} />
        )}
          </IonContent>
        </IonPage>
        <StartModal />
      </>
    );
  };
  
  export default ManageBookings;
  