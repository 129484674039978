import { IonButton, IonContent, IonPage } from "@ionic/react";
import StartModal from "../../../components/modals/StartModal";
import "./Invoices.scss";
import { useEffect, useRef, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import moment from "moment";
import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { workshop } from "../../../services/data.service";
import { getInvoices } from "../../../services/invoices.service";
import {
  getServiceinvoices,
  updateService,
} from "../../../services/services.service";
import { upload } from "../../../services/upload.service";
import DashModal from "../../../components/modals/DashModal";

const Invoices: React.FC = () => {
  const fileInput = useRef(null);
  const [invoices, setInvoices]: any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState([]);
  const [activeId, setActiveId] = useState();

  const [showModal, setShowModal]: any = useState(false);
  const [modaldata, setModaldata]: any = useState(false);

  const titleCaseWord = (word: string) => {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  };
  useEffect(() => {
    workshop.subscribe((workshopResponse: any) => {
      console.log(workshopResponse);
      if (workshopResponse) {
        setWorkshopInfo(workshopResponse);
        getAllInvoices(workshopResponse._id.$oid);
      }
    });
  }, []);

  const getAllInvoices = (id: string) => {
    getServiceinvoices(id).then((response: any) => {
      console.log(response);
      setInvoices(response);
    });
  };

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    upload(file, "invoice", workshopInfo._id.$oid)
      .then((response: any) => {
        console.log(response);
        const params = {
          _id: {
            $oid: activeId,
          },
          invoice_url: response.url,
        };
        updateServiceUrl(params);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const updateServiceUrl = (data: any) => {
    updateService(data)
      .then((response: any) => {
        getAllInvoices(workshopInfo._id.$oid);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const ShowModal = (data: any) => {
    setModaldata(data);
    setShowModal(true);
  };

  const modalEevent = (data: any) => {
    setModaldata(null);
    setShowModal(false);
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M13,0 C14.6568542,-3.04359188e-16 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 2.02906125e-16,14.6568542 0,13 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L13,0 Z M13,9 L3,9 L3,11 L13,11 L13,9 Z M13,5 L3,5 L3,7 L13,7 L13,5 Z"
                      id="Combined-Shape"
                    ></path>
                  </svg>
                  <span>Upload Invoices</span>
                </h6>
              </div>
              <div className="content-container">
                {invoices ? (
                  <Grid style={{ height: "100%" }} data={invoices}>
                    <Column
                      field="serv_booking_id"
                      title="ID"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "left" }}>
                          <a
                            className="pointer"
                            onClick={() => {
                              ShowModal(props.dataItem);
                            }}
                          >
                            {props.dataItem.serv_booking_id}
                          </a>
                        </td>
                      )}
                    />
                    <Column
                      title="Service Date"
                      field="services_info.service_date"
                      cell={(props: GridCellProps) => (
                        <td>
                          {moment(
                            new Date(
                              props.dataItem.services_info.service_date.$date
                            ),
                            "YYYY-MM-DD",
                            true
                          ).format("dddd Do MMM")}
                        </td>
                      )}
                    />
                    <Column
                      field="vehicle.registration_number"
                      title="Reg Nr."
                    />
                    <Column
                      field="market_price.market_price.vehicle_make"
                      title="Make"
                    />
                    <Column
                      field="market_price.market_price.vehicle_model"
                      title="Model"
                    />
                    <Column
                      field="service_status"
                      title="Status"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          {titleCaseWord(props.dataItem.service_status)}
                        </td>
                      )}
                    />
                    <Column
                      field="service.price"
                      title="Upload Invoice"
                      width="200"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          <IonButton
                            size="small"
                            color={
                              !props.dataItem.invoice_url
                                ? "warning"
                                : "success"
                            }
                            onClick={(e) => {
                              setActiveId(props.dataItem._id.$oid);
                              setTimeout(() => {
                                // @ts-ignore
                                fileInput.current && fileInput.current.click();
                              }, 500);
                            }}
                          >
                            {!props.dataItem.invoice_url
                              ? "Upload"
                              : "Uploaded"}
                          </IonButton>
                          {props.dataItem.invoice_url ? (
                            <IonButton
                              size="small"
                              color="primary"
                              onClick={(e) => {
                                window.open(
                                  props.dataItem.invoice_url,
                                  "_blank"
                                );
                              }}
                            >
                              View
                            </IonButton>
                          ) : null}
                        </td>
                      )}
                    />
                  </Grid>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <input
            ref={fileInput}
            className="hidden-input"
            type="file"
            onChange={(ev) => handleFileInput(ev)}
          />
          {showModal ? (
            <DashModal
              data={modaldata}
              type="booking"
              onModalEevent={modalEevent}
              showActions={false}
            />
          ) : null}
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Invoices;
