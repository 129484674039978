import {
  IonContent,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import StartModal from "../../../components/modals/StartModal";
import "./Diary.scss";

import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { getSchedules } from "../../../services/schedules.service";
import moment from "moment";
import DashModal from "../../../components/modals/DashModal";
// import { getWorkshop } from "../../../services/workshop.service";
import {
  Scheduler,
  WeekView,
  WorkWeekView,
  SchedulerItem,
  SchedulerItemProps,
  useSchedulerEditItemFormItemContext,
} from "@progress/kendo-react-scheduler";
import { Day } from "@progress/kendo-date-math";
import {
  SchedulerViewItem,
  SchedulerViewItemHandle,
  SchedulerViewItemProps,
} from "@progress/kendo-react-scheduler";

const Diary: FC = () => {
  const [schedules, setSchedules]: any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState(null);
  const [showModal, setShowModal]: any = useState(false);
  const [modalData, setModaldata]: any = useState(null);

  const today = new Date();

  useEffect(() => {
    loadworkshop();
  }, []);

  const loadworkshop = () => {
    // getWorkshop()
    //   .then((workshopResponse: any) => {
    //     console.log(workshopResponse);
    //     setWorkshopInfo(workshopResponse);
    //     console.log(workshopInfo);
    //     allSchedules(workshopResponse);
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   });
  };

  const currentYear = new Date().getFullYear();
  const parseAdjust = (eventDate: any) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };

  const allSchedules = (scheduleData: any) => {
    console.log(scheduleData);
    getSchedules(scheduleData._id.$oid)
      .then((scheduleResponse: any) => {
        let paramsdata: any = [];
        const dates = [
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 1
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 2
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 3
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 4
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 5
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 6
            ).setHours(8, 0, 0)
          ),
          new Date(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 7
            ).setHours(8, 0, 0)
          ),
        ];
        dates.forEach((date: any, index: any) => {
          console.log(date);
          if (scheduleResponse.length > 0) {
            let found = false;
            scheduleResponse.forEach((schedule: any) => {
              if (date.getTime() === schedule.date.$date) {
                paramsdata.push({
                  id:
                    schedule.id_business +
                    "_" +
                    Math.random().toString(36).substr(2, 9),
                  start: parseAdjust(schedule.date.$date),
                  end: parseAdjust(
                    new Date(schedule.date.$date).setHours(17, 0, 0)
                  ),
                  isAllDay: true,
                  title: `Bookings ${schedule.bookings}`,
                  scheduleData: schedule,
                  description: `Rate R ${schedule.rate.toFixed(2)}`,
                  roomId: 1,
                  ownerID: scheduleData._id.$oid,
                  personId: scheduleData._id.$oid,
                });
                found = true;
              }
            });
            if (!found) {
              paramsdata.push({
                id:
                  scheduleData._id.$oid +
                  "_" +
                  Math.random().toString(36).substr(2, 9),
                start: parseAdjust(new Date(date)),
                end: parseAdjust(new Date(date).setHours(17, 0, 0)),
                isAllDay: true,
                title: `Bookings ${0}`,
                scheduleData: {
                  date: new Date(date),
                  id_business: scheduleData._id.$oid,
                  parts_discount: scheduleData.workshop.parts_discount,
                  rate: scheduleData.workshop.rate,
                  bookings: 0,
                  slots_per_hour: scheduleData.workshop.slots_per_hour,
                  workshop_hours: scheduleData.workshop.workshop_hours,
                  rate_discount: scheduleData.workshop.rate_discount,
                },
                description: `Rate R ${scheduleData.workshop.rate.toFixed(2)}`,
                roomId: 1,
                ownerID: scheduleData._id.$oid,
                personId: scheduleData._id.$oid,
              });
            }
          } else {
            paramsdata.push({
              id:
                scheduleData._id.$oid +
                "_" +
                Math.random().toString(36).substr(2, 9),
              start: parseAdjust(date),
              end: parseAdjust(new Date(date).setHours(17, 0, 0)),
              isAllDay: true,
              title: `Bookings ${0}`,
              scheduleData: {
                date: date,
                id_business: scheduleData._id.$oid,
                parts_discount: scheduleData.workshop.parts_discount,
                rate: scheduleData.workshop.rate,
                bookings: 0,
                slots_per_hour: scheduleData.workshop.slots_per_hour,
                workshop_hours: scheduleData.workshop.workshop_hours,
                rate_discount: scheduleData.workshop.rate_discount,
              },
              description: `Rate R ${scheduleData.workshop.rate.toFixed(2)}`,
              roomId: 1,
              ownerID: scheduleData._id.$oid,
              personId: scheduleData._id.$oid,
            });
          }
        });

        setSchedules(paramsdata);
        setTimeout(() => {
          console.log(schedules);
        }, 2000);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const ShowModal = (data: any) => {
    setModaldata(data);
    setShowModal(true);
  };

  const modalEevent = (data: any) => {
    setModaldata(null);
    setShowModal(false);
    allSchedules(workshopInfo);
  };

  const getRectangle = (element: HTMLElement | null) => {
    if (!element) {
      return { top: 0, left: 0, width: 0, height: 0 };
    }
    const top = element.offsetTop;
    const left = element.offsetLeft;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    return {
      top,
      left,
      width,
      height,
    };
  };

  const ProportionalViewItem = (props: SchedulerViewItemProps) => {
    const item: RefObject<SchedulerViewItemHandle> = createRef();
    const [display, setDisplay] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false);

    const reflow = () => {
      const firstSlot =
        props.slots && props.slots.length ? props.slots[0] : null;

      if (props.slots.length === 0 || !firstSlot || !firstSlot._ref.current) {
        setDisplay(false);
        return;
      }

      const slotRect = getRectangle(firstSlot._ref.current.element);
      const pxPerMilisecond =
        (props.vertical ? slotRect.height : slotRect.width) /
        (firstSlot.end.getTime() - firstSlot.start.getTime());

      const offset =
        (props.start.getTime() - firstSlot.start.getTime()) * pxPerMilisecond;

      const total =
        (props.end.getTime() - props.start.getTime()) * pxPerMilisecond;

      const element = item.current && item.current.element;
      const itemRect = getRectangle(element);

      if (!element) {
        return;
      }

      if (props.vertical) {
        element.style.height = `${total + (offset < 0 ? offset : 0)}px`;
        element.style.top = `${itemRect.top + (offset < 0 ? 0 : offset)}px`;
      } else {
        element.style.width = `${total}px`;
        element.style.left = `${itemRect.left + (offset < 0 ? 0 : offset)}px`;
      }

      setVisible(true);
    };

    useEffect(() => {
      reflow();
    });

    const handleClick = useCallback(() => {
      ShowModal(props.dataItem);
      console.log("clicked");
    }, [props.dataItem]);
    return (
      <SchedulerViewItem
        {...props}
        ref={item}
        onClick={handleClick}
        style={{
          visibility: visible ? undefined : "hidden",
          display: display ? undefined : "none",
          ...props.style,
        }}
      />
    );
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M5,0 L5,2 L11,2 L11,0 L13,0 L13,2 L16,2 L16,16 L0,16 L0,2 L3,2 L3,0 L5,0 Z M9,10 L7,10 L7,12 L9,12 L9,10 Z M5,10 L3,10 L3,12 L5,12 L5,10 Z M13,10 L11,10 L11,12 L13,12 L13,10 Z M5,6 L3,6 L3,8 L5,8 L5,6 Z M13,6 L11,6 L11,8 L13,8 L13,6 Z M9,6 L7,6 L7,8 L9,8 L9,6 Z"
                      id="Combined-Shape"
                    ></path>
                  </svg>
                  <span>Diary</span>
                </h6>
              </div>
              <div className="content-container">
                {schedules ? (
                  <>
                    <Scheduler
                      data={schedules}
                      item={ProportionalViewItem}
                      editable={true}
                      onDataChange={() => allSchedules(workshopInfo)}
                    >
                      <WorkWeekView
                        title="Work Week"
                        workWeekStart={Day.Monday}
                        workWeekEnd={Day.Friday}
                      />
                      <WeekView
                        title="Full Week"
                        workWeekStart={Day.Monday}
                        workWeekEnd={Day.Friday}
                      />
                    </Scheduler>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Bays</th>
                          <th>Bookings</th>
                          <th>Allocated Hrs</th>
                          <th>Labour Rate (P/Hr)</th>
                          <th>Discount (Labour)</th>
                          <th>Discount (Parts)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedules.map((s: any, index: number) => {
                          return (
                            <tr
                              key={"schedule_" + index}
                              id={"schedule_" + index}
                              className="pointer"
                              onClick={() => ShowModal(s)}
                            >
                              <td>
                                {moment(
                                  s.scheduleData.date.$date
                                    ? new Date(s.scheduleData.date.$date)
                                    : s.scheduleData.date,
                                  "YYYY-MM-DD",
                                  true
                                ).format("dddd Do MMM")}
                              </td>
                              <td>{s.scheduleData.slots_per_hour}</td>
                              <td>{s.scheduleData.bookings}</td>
                              <td>{s.scheduleData.workshop_hours}</td>
                              <td>
                                R {parseFloat(s.scheduleData.rate).toFixed(2)}
                              </td>
                              <td>{s.scheduleData.rate_discount}%</td>
                              <td>{s.scheduleData.parts_discount}%</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
          {showModal ? (
            <DashModal
              data={modalData}
              type="schedule"
              onModalEevent={modalEevent}
            />
          ) : null}
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Diary;
