import { IonContent, IonPage } from "@ionic/react";

import StartModal from "../../../components/modals/StartModal";
import "./ManageUsers.scss";

import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { workshop } from "../../../services/data.service";

import {
  Grid,
  GridColumn as Column,
  GridCellProps,
} from "@progress/kendo-react-grid";
// import { getAdminUsers } from "../../../services/admin.service";

const ManageUsers: React.FC = () => {
  const [users, setUsers]: any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState([]);

  // generateInvoice
  // updateInvoice

  useEffect(() => {
    workshop.subscribe((workshopDetails: any) => {
      console.log(workshopDetails);
      if (workshopDetails) {
        setWorkshopInfo(workshopDetails);
      }
    });
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    // getAdminUsers().then((adminUsers: any) => {
    //   console.log("adminUsers",adminUsers)
    //   setUsers(adminUsers);
    // });
  };

  useEffect(() => {}, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-people"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                    ></path>
                  </svg>
                  <span>Manage Users</span>
                </h6>
              </div>
              <div className="content-container">
                {users ? (
                  <Grid style={{ height: "100%" }} data={users} sortable={true}>
                    <Column field="name" title="Name" />
                    <Column field="surname" title="Surname" />
                    <Column field="email" title="Email" />
                    <Column field="phonenumber" title="Mobile" />
                    <Column
                      field="roles"
                      title="Roles"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          {props.dataItem.roles.map((r: any) => {
                            return (
                              <>
                                <span className="badge bg-dark mr-2">{r}</span>
                                <span>&nbsp;</span>
                              </>
                            );
                          })}
                        </td>
                      )}
                    />
                    <Column
                      field="verified"
                      title="Verified"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          {props.dataItem.verified ? (
                            <span className="badge bg-success mr-2">True</span>
                          ) : (
                            <span className="badge bg-danger mr-2">False</span>
                          )}
                        </td>
                      )}
                    />
                  </Grid>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default ManageUsers;
