import { LIST, POST, PUT, GET, DELETE } from "./api.service" 

declare var google: any;

export const getalllocations = () => {
    return LIST('locations/public');
}
export const getlocationbyid = (data: any) => {
    return GET('location/public', data);
}
export const createlocation = (data: any) => {
    return POST('locations/public', data);
}
export const updatelocation = (data: any) => {
    return PUT('location/public', data);
}
export const deletelocation = (data: any) => {
    return DELETE('location/public', data);
}

export const getLocation = () => {

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition( (position) => {
            resolve(position)
        })
    });
}

export const initService = async (searchstring: any) => {
    return new Promise((resolve, reject) => {

        const displaySuggestions = (predictions: google.maps.places.QueryAutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
              reject(status)
              return
            }
        
            resolve(predictions)
        };

        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: searchstring }, displaySuggestions);
    });
}

export const Geocode = (address: string) => {
    return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'address': address}, (results: any, status: any) => {
            if (status == 'OK') {
              resolve(results[0])
            } else {
              reject('Geocode was not successful for the following reason: ' + status);
            }
        });
    })
}