import { LIST, PUT, POST, GET } from "./api.service" 

// export const getAdminUsers = () => {
//     return LIST('admin/manage/users');
// }
export const getAdminInvoices = () => {
    return LIST('admin/manage/invoices');
}
export const getAdminWorkshops = (data:any) => {
    return GET(`admins/get_all_workshops_list`,data);
}

export const activateWorkshop = (data: any) => {
    return PUT('admins/activate', data);
}

export const deactivateWorkshop = (data: any) => {
    return PUT('admins/deactivate-account', data);
}

export const updateAfdminUser = (data: any) => {
    return PUT('admin/manage/user', data);
}
export const updateAdminInvoice = (data: any) => {
    return PUT('admin/manage/invoice', data);
}
export const updateAdminWorkshop = (data: any) => {
    return PUT('admin/manage/workshop', data);
}

export const signInAdmin = (data: any) => {
    return POST('admins/login-admin-user', data);
}

export const getAllBookingsAPI = (data: any) => {
    return GET('admins/get_all_bookings', data);
}

export const getWorkshopById = (data: any, id: string) => {
    return GET(`consumer/get_active_workshop_by_id/${id}`, data);
}
