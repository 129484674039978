import React, { useEffect, useState } from 'react';
import "../modals/ModelStyling.scss"
import { updateBookingStatus } from '../../services/workshop.service';
import { getWorkshopById } from '../../services/admin.service';

const AdminDashModal:  React.FC<any>= ({handleShowSnackbar,setSnackbarMessage, modaldata, onClose , id , payment}, workshopDetails) => {


    console.log(workshopDetails, "workshopDetails")

  return (
    <div className="modal"> {/* Apply the 'modal' class */}
    <div className="modal-height">
    <div className="modal-content"> {/* Apply the 'modal-content' class */}
      <h2>Vehicle Details</h2>
        <p>Make: {modaldata.vehicle_details.make}</p>
        <p>Model: {modaldata.vehicle_details.model}</p>
        <p>Registration: {modaldata.vehicle_details.registration_number}</p>
        <h2>Workshop Details</h2>
       <p>Name: {workshopDetails.workshop_name}</p>
        <p>Address: {workshopDetails.address}</p>
        <p>Email: {workshopDetails.email}</p>
        <p>Contact Number: {workshopDetails.phone_number}</p>  
        <h2>Customer Details</h2>
        <p>Name: {modaldata.name} {modaldata.surname}</p>
        <p>Email: {modaldata.email}</p>
        <p>Phone Number: {modaldata.phone_number}</p>
        <p>Address: {modaldata.address}</p>
        <p>Area Code: {modaldata.area_code}</p>
        <p>Province: {modaldata.province}</p>
        <p>Completed Payment?: {String(payment)}</p>
        <h2>Part Details</h2>
        <p>Engine Capacity: {modaldata.vehicle_details.engine_capacity}</p>
        <p>Gearbox Type: {modaldata.vehicle_details.gearbox_type}</p>
        <p>Mileage: {modaldata.vehicle_details.milleage}</p>
        <p>Original Parts: {modaldata.original_parts.join(', ')}</p>
        <p>Parts Needed: {modaldata.parts_needed.join(', ')}</p>
        <h2>Service Details</h2>
        <p>Service Date: {modaldata.service_date}</p>
        <p>Service Plan: {modaldata.service_plan}</p>
        <p>Status: {modaldata.status}</p>

        

        <button className="modal-close-button" onClick={onClose}>Close</button> {/* Apply a custom class for the close button */}
      </div>
    </div>

    </div>
  );
};


export default AdminDashModal;